<script lang="ts" setup>
interface Props {
  size: "sm" | "md" | "lg";
}

const LogoSize = {
  sm: "text-base",
  md: "text-2xl",
  lg: "text-5xl",
};

defineProps<Props>();
</script>

<template>
  <NuxtLink to="/">
    <h1 class="font-bold text-white tracking-widest font-sans" :class="LogoSize[size]">RAVIISH</h1>
  </NuxtLink>
</template>
